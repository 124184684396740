import { Button, Container, TextField, Typography } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import config from '../config';
import { useWithLoading } from '../hooks/useWithLoading';
import logo from '../img/logo.png';
import { errorHandler, makeHeaders } from '../utils/axios-utils';

const numericRegex = /^[0-9\b]+$/;

function Login() {
    const withLoading = useWithLoading();
    const navigate = useNavigate();

    const [customerNumber, setCustomerNumber] = useState("");
    const [customerPin, setCustomerPin] = useState("");

    useEffect(() => {
        if (Cookies.get("stella-customer-number") != null && Cookies.get("stella-customer-pin") != null) {
            navigate("/");
        }
    }, []); // eslint-disable-line

    const handlePinChange = (e) => {
        let pin = e.target.value;
        if (pin === "" || numericRegex.test(pin)) {
            setCustomerPin(pin);
        }
    };

    const submit = () => {
        if (!validateInput()) return;

        withLoading(axios.get(`${config.API_BASE_URL}/portal/me`, { headers: makeHeaders(customerNumber, customerPin) })
            .then(() => {
                Cookies.set("stella-customer-number", customerNumber);
                Cookies.set("stella-customer-pin", customerPin);
                navigate("/");
            })
            .catch(errorHandler(code => {
                if (code === 401) {
                    toast.error("Número de Cliente ou PIN inválidos.");
                    return true;
                }

                return false;
            })));
    };

    const validateInput = () => {
        if (customerNumber === "") {
            toast.error("O número de cliente não pode ser vazio.");
            return false;
        }

        if (customerPin === "") {
            toast.error("O PIN não pode ser vazio.");
            return false;
        }

        return true;
    };

    return (
        <Container sx={{ p: 3, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={logo} alt='' style={{ display: "block", margin: "0 auto", height: 100 }} />
            <Typography sx={{ mt: 3, fontSize: 40, fontWeight: "bold" }} variant="h1">{config.COMPANY_NAME}</Typography>
            <Typography>Portal do Cliente</Typography>
            <TextField sx={{ mt: 3, maxWidth: 500, display: "block" }} label="Número de Cliente" type="number" autoComplete='off' fullWidth onChange={e => setCustomerNumber(e.target.value)} value={customerNumber} />
            <TextField sx={{ mt: 1, maxWidth: 500, display: "block" }} label="PIN" type="password" inputMode='numeric' fullWidth onChange={handlePinChange} value={customerPin} />
            <Button variant="contained" fullWidth sx={{ mt: 2, maxWidth: 500, color: "white" }} onClick={submit}>Entrar</Button>
        </Container>
    )
}

export default Login;