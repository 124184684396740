import React, { useEffect } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Main from './pages/Main';
import Login from './pages/Login';
import config from './config';
import { LoadingProvider } from './hooks/useWithLoading';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/",
    element: <Main />
  }
]);

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF9DB0"
    }
  }
});

function App() {
  useEffect(() => {
    document.title = `${config.COMPANY_NAME} - Customer Portal`;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadingProvider>
        <RouterProvider router={router} />
      </LoadingProvider>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
