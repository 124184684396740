import Cookies from "js-cookie";
import { toast } from "react-toastify";
import config from "../config";

export const headers = () => {
    let cNumber = Cookies.get("stella-customer-number");
    let cPin = Cookies.get("stella-customer-pin");

    if (cNumber == null || cPin == null) {
        window.location.href = "/login";
        return;
    }

    let opts = {
        headers: makeHeaders(cNumber, cPin),
    };

    return opts;
};

export const errorHandler = (customHandler = null) => {
    return (err) => {
        let statusCode = err.response?.status;

        if (customHandler != null) {
            if (customHandler(statusCode, err.response?.data)) return;
        }

        switch (statusCode) {
            case 401:
                Cookies.remove("stella-customer-number");
                Cookies.remove("stella-customer-pin");
                window.location.href = "/login";
                break;
            default:
                if (config.DEBUG_MODE) console.error(err);
                toast.error("Ocorreu um erro inesperado.");
                break;
        }
    };
};

export const makeHeaders = (cNumber, cPin) => {
    return {
        "x-customer-number": cNumber,
        "x-customer-pin": cPin,
        "x-token-id": config.TOKEN_ID
    };
}