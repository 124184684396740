import { Box, Button, CircularProgress, Container, Divider, Paper, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import config from '../config';
import { useWithLoading } from '../hooks/useWithLoading';
import { errorHandler, headers } from '../utils/axios-utils';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

function Main() {
    const navigate = useNavigate();
    const withLoading = useWithLoading();

    const transactionInfoRef = useRef();

    const [customer, setCustomer] = useState(null);
    const [records, setRecords] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [transactionNum, setTransactionNum] = useState(0);
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [page, setPage] = useState(0);

    useEffect(() => {
        let getCustomer = axios.get(`${config.API_BASE_URL}/portal/me`, headers())
            .then((res) => setCustomer(res.data))
            .catch(errorHandler());

        let getRecords = axios.get(`${config.API_BASE_URL}/portal/records`, headers())
            .then(res => setRecords(res.data))
            .catch(errorHandler());

        let getTransactionNum = axios.get(`${config.API_BASE_URL}/portal/transaction-count`, headers())
            .then(res => setTransactionNum(res.data.count))
            .catch(errorHandler());

        withLoading(Promise.all([getCustomer, getRecords, getTransactionNum]));
    }, []); // eslint-disable-line

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [transactions]); // eslint-disable-line

    useEffect(() => {
        loadTransactions(page);
    }, [page, transactionNum]); // eslint-disable-line

    const onScroll = () => {
        const scrollTop = document.documentElement.scrollTop
        const scrollHeight = document.documentElement.scrollHeight
        const clientHeight = document.documentElement.clientHeight

        if (scrollTop + clientHeight >= scrollHeight) {
            setPage(page + 1)
        }
    }

    const logout = () => {
        Cookies.remove("stella-customer-number");
        Cookies.remove("stella-customer-pin");
        navigate("/login");
    };

    const getCustomerInfo = () => {
        return <Box>
            <Typography sx={{ fontSize: 20 }}>Olá, {customer.name}!</Typography>
            <Typography sx={{ fontSize: 15 }} color="text.secondary">Não és tu? <Button onClick={logout}>Sair</Button></Typography>
            <Divider sx={{ mt: 2 }} />
        </Box>
    };

    const getRecordsInfo = () => {
        if (records.length === 0) return null;

        return <Box sx={{ mt: 3 }}>
            <Typography sx={{ fontSize: 25, mb: 1 }} color="primary" fontWeight="bold">Ainda tens...</Typography>
            {records.map((r, index) => <Paper key={index} sx={{ p: 2, mb: 1 }}>
                <Typography>{r._id}</Typography>
                <Typography color="primary" fontWeight="bold">x{r.count}</Typography>
            </Paper>)}
        </Box>
    };

    const loadTransactions = async (page) => {
        if (transactions.length >= transactionNum) return;
        if (loadingTransactions) return;
        setLoadingTransactions(true);

        axios.get(`${config.API_BASE_URL}/portal/transactions?page=${page}`, headers())
            .then(res => {
                setTransactions([...transactions, ...res.data]);
                setLoadingTransactions(false);
            })
            .catch(errorHandler(() => {
                setLoadingTransactions(false);
                return false;
            }));
    };

    const getTransactionsInfo = () => {
        return <Box sx={{ mt: 3 }} ref={transactionInfoRef}>
            <Typography sx={{ fontSize: 25, mb: 1 }} color="primary" fontWeight="bold">Visitas recentes...</Typography>
            {transactions.map((t, index) => <Paper key={index} sx={{ p: 2, mb: 1 }}>
                <Typography>{t.serviceName}</Typography>
                <Typography color="text.secondary" fontSize={14}>{moment(t.date).format("DD/MM/YYYY HH:mm")}</Typography>
            </Paper>)}
            {!loadingTransactions ? null : <CircularProgress variant="indeterminate" />}
        </Box>
    };

    if (customer == null) return null;

    return (
        <Container sx={{ p: 3 }}>
            {getCustomerInfo()}
            {getRecordsInfo()}
            {getTransactionsInfo()}
        </Container>
    )
}

export default Main;