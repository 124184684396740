import { Backdrop, CircularProgress } from '@mui/material';
import React, { useContext, useState } from 'react'
import { createContext } from 'react';

const LoadingContext = createContext(null);

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const value = async (promise) => {
        setLoading(true);
        await promise;
        setLoading(false);
    };

    return <LoadingContext.Provider value={value}>
        {children}
        <Backdrop sx={{ color: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="primary" />
        </Backdrop>
    </LoadingContext.Provider>;
}

export function useWithLoading() {
    return useContext(LoadingContext);
}